import React from 'react';
import { NumberToLetter } from 'convertir-nombre-lettre';

function NombreEnLettres(props) {
  const { nombre } = props;
  const nombreEnLettres = NumberToLetter(nombre);

  return (
    <span>
      {nombreEnLettres}
    </span>
  );
}

export default NombreEnLettres;
