import React from 'react'
import react, { useState, useRef, useEffect} from 'react';
import './App.css';
import NombreEnLettres from './conver.js';
import Ligne_patch from './Ligne_patch';



const Medic_interval_patch = ({
    posocp1, posocp2,
    choix_poso_init,
    init_intervalle,
    nom_medicament,
    setrendu,
    intervalle_mode,
    init_duree
}) => {

  const [qqte_Ligne1, setqqte_Ligne1] = useState(1);
  const [qqte_Ligne2, setqqte_Ligne2] = useState(1);
  const [ajout_cp, setajout_cp] = useState(false);

  const [poso_cp1, setposo_cp1] = useState(choix_poso_init[0]);

  const [poso_cp2, setposo_cp2] = useState(null);
  const [intervalle, setintervalle] = useState(init_intervalle);
  const [duree, setduree] = useState(init_duree);
  const [duree_2, setduree_2] = useState(null);



  useEffect(() => {
    if (ajout_cp==true){
      setposo_cp2(choix_poso_init[0]);
    }
    
  }, [ajout_cp]);


  useEffect(() => {
    
    setrendu(
      {
    mode_intervalle: true,
    nom : nom_medicament,
    nbs_cp1: qqte_Ligne1,
    nbs_cp2 : qqte_Ligne2,
    poso1: poso_cp1,
    poso2 : poso_cp2,
    intervalle : intervalle,
    duree : duree+duree_2,
  }
    )

  });



  const handleDropdownChangeduree = (event) => {
    setduree(parseInt(event.target.value));
  };

    return (
        <div className="med_a_inter">

          <div className="med_a_2">
            <div className='text_poso'>Posologie :</div>
                <div className='ligne'>
                    <span className='ligne_2'>
                      
                      <Ligne_patch
                            setqqte_Ligne={setqqte_Ligne1}
                            choix_poso_init={choix_poso_init}
                            setchoix_poso_loc_ligne={setposo_cp1}
                            // récupere la poso
                            /> 
                            </span>
                   
                    {ajout_cp==false?<span>
                      <button className="btn btn-outline-dark btn-sm" onClick={() => {setajout_cp(true); ; setposo_cp2(choix_poso_init[0]); }}style={{fontSize: '80%'}}><span className='texte_bouton_ajout'>Ajout d'un patch de posologie différente</span></button>
                            </span>:null}

                    {ajout_cp==true?<span>
                        <Ligne_patch
                            setqqte_Ligne={setqqte_Ligne2}
                            choix_poso_init={choix_poso_init}
                            setchoix_poso_loc_ligne={setposo_cp2}
                            // récupere la poso
                            /> </span>:null}
                </div>
          </div>
          {/* <div className="intervalle">Intervalle minimum entre 2 prises : {" "}
          <input type="text" value={intervalle} onChange={(e) => setintervalle(e.target.value)}  style={{ width: '50px','-webkit-appearance': 'none', '-moz-appearance': 'textfield', 'appearance': 'textfield' }} />
          {" "} heures
          </div> */}
          <div className="duree">Durée de prescription : {" "}
          {(duree == 7 | duree==14 | duree==21 | duree==28 ) ?  <select id="dropdown" value={duree} onChange={handleDropdownChangeduree} style={{ width: '60px'}}>
                    <option value={7}>7</option>
                    <option value={14}>14</option>
                    <option value={21}>21</option>
                    <option value={28}>28</option>
                    <option value={0}>autre</option>
                </select> : 
                          <input type="text" value={duree_2} onChange={(e) => setduree_2(e.target.value)}  style={{ width: '50px'}} />
                        }
                
               {" "} jours

                </div>
        </div>
      );

}

export default Medic_interval_patch
