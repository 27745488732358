import React from 'react'
import react, { useState, useRef, useEffect} from 'react';
import './App.css';
import NombreEnLettres from './conver.js';
import Ligne from './Ligne';



const Afficheur = ({
    donnee
}) => {

if (donnee.cp_gellule==true){
  if (donnee.poso2 == null){
    return (

      <div style={{fontSize: '100%'}}>
        <strong>{donnee.nom}</strong> : <NombreEnLettres nombre={donnee.nbs_cp1} />  {donnee.nbs_cp1 ==1 ? "comprimé" : "comprimés"} de <NombreEnLettres nombre={donnee.poso1} /> milligrammes ({donnee.poso1} mg), {donnee.intervalle !=1 ? "à prendre si douleur, toute les " : null} {donnee.intervalle!=1 ? <NombreEnLettres nombre={donnee.intervalle} /> : null} {donnee.intervalle!=1 ? "heures au maximum,": null} {donnee.intervalle==1 ? "à prendre si douleur, toutes les heures au maximum,": null}pendant <NombreEnLettres nombre={donnee.duree} /> jours
      <br/>
      </div>
        // <div>
        //      {donnee[0]} : {donnee[1]} comprimé de {donnee[2]} mg
        // </div>
      );
  }

  if (donnee.poso2 != null){
    return (

      <div style={{fontSize: '100%'}}>
        <strong>{donnee.nom}</strong> : <NombreEnLettres nombre={donnee.nbs_cp1} />  {donnee.nbs_cp1 ==1 ? "comprimé" : "comprimés"} de <NombreEnLettres nombre={donnee.poso1} /> milligrammes ({donnee.poso1} mg) et <NombreEnLettres nombre={donnee.nbs_cp2} />  {donnee.nbs_cp2 ==1 ? "comprimé" : "comprimés"} de <NombreEnLettres nombre={donnee.poso2} /> milligrammes ({donnee.poso2} mg) par prise, {donnee.intervalle !=1 ? "à prendre si douleur, toute les " : null} {donnee.intervalle!=1 ? <NombreEnLettres nombre={donnee.intervalle} /> : null} {donnee.intervalle!=1 ? "heures au maximum," : null} {donnee.intervalle==1 ? "à prendre si douleur, toutes les heures au maximum," : null}pendant <NombreEnLettres nombre={donnee.duree} /> jours
      </div>
        // <div>
        //      {donnee[0]} : {donnee[1]} comprimé de {donnee[2]} mg
        // </div>
      );
  }
}

if (donnee.cp_gellule==false){
  if (donnee.poso2 == null){
    return (

      <div style={{fontSize: '100%'}}>
        <strong>{donnee.nom}</strong> : {donnee.nbs_cp1==1 ? null : <NombreEnLettres nombre={donnee.nbs_cp1} />}  {donnee.nbs_cp1 ==1 ? " une gélule" : "gélules"} de <NombreEnLettres nombre={donnee.poso1} /> milligrammes ({donnee.poso1} mg), {donnee.intervalle !=1 ? "à prendre si douleur, toute les " : null} {donnee.intervalle!=1 ? <NombreEnLettres nombre={donnee.intervalle} /> : null} {donnee.intervalle!=1 ? "heures au maximum," : null} {donnee.intervalle==1 ? "à prendre si douleur, toutes les heures au maximum," : null}pendant <NombreEnLettres nombre={donnee.duree} /> jours
      </div>
        // <div>
        //      {donnee[0]} : {donnee[1]} comprimé de {donnee[2]} mg
        // </div>
      );
  }

  if (donnee.poso2 != null){
    return (

      <div style={{fontSize: '100%'}}>
        <strong>{donnee.nom}</strong> : {donnee.nbs_cp1==1 ? null : <NombreEnLettres nombre={donnee.nbs_cp1} />}  {donnee.nbs_cp1 ==1 ? " une gélule" : "gélules"} de <NombreEnLettres nombre={donnee.poso1} /> milligrammes ({donnee.poso1} mg) et {donnee.nbs_cp2==1 ? null : <NombreEnLettres nombre={donnee.nbs_cp2} />}  {donnee.nbs_cp2 ==1 ? "une gélule" : "gélules"} de <NombreEnLettres nombre={donnee.poso2} /> milligrammes ({donnee.poso2} mg) par prise, {donnee.intervalle !=1 ? "à prendre si douleur, toute les " : null} {donnee.intervalle!=1 ? <NombreEnLettres nombre={donnee.intervalle} /> : null} {donnee.intervalle!=1 ? "heures au maximum," : null} {donnee.intervalle==1 ? "à prendre si douleur, toutes les heures au maximum," : null}pendant <NombreEnLettres nombre={donnee.duree} /> jours
      </div>
        // <div>
        //      {donnee[0]} : {donnee[1]} comprimé de {donnee[2]} mg
        // </div>
      );
  }
}



}

export default Afficheur
