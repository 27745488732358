import React, { useState, useRef, useEffect } from 'react';

import './App.css';
import './ToggleSwitch.css'
import ReactToPrint from 'react-to-print';
import Test from './test.js';
import Medic_interval from './Medic_interval';
import Medic_MS from './Medic_MS';
import Afficheur from './afficheur';
import AfficheurMS from './afficheurMS';
import Afficheur_patch from './afficheur_patch';
import Medic_interval_patch from './Medic_interval_patch'
import DateInLetters from './date';
import "bootstrap/dist/css/bootstrap.min.css";

import Logo from './image/logo.PNG';
import Ordo1 from './image/ordo1.PNG';
import Ordo2 from './image/ordo2.PNG';
import Ordo3 from './image/ordo3.PNG';

import i1 from './image/i1.png';

import { Accordion, Button, Card } from 'react-bootstrap';

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import { logEvent } from "firebase/analytics";



function App() {

  const firebaseConfig = {
    apiKey: "AIzaSyDVFn5EFhpJi3r4jktYz_-QTRES8kbLN-k",
    authDomain: "ordodouleur-c6bbe.firebaseapp.com",
    projectId: "ordodouleur-c6bbe",
    storageBucket: "ordodouleur-c6bbe.appspot.com",
    messagingSenderId: "404194517564",
    appId: "1:404194517564:web:b895c57726568b8eca5939",
    measurementId: "G-D94Y7Z8VLL"
  };
  
// Initialiser Firebase
const app = initializeApp(firebaseConfig);

// Initialiser Firebase Analytics
const analytics = getAnalytics(app);


const analy_oxycodone = () => {
  logEvent(analytics, 'clic_oxycodone')
};

const analy_morphine = () => {
  logEvent(analytics, 'clic_skenan')
};

const analy_duro = () => {
  logEvent(analytics, 'clic_durogesic')
};



  const [options, setOptions] = useState({
    poso_oxycontin: "10",
    poso_actiskenan: "20",
    medic1 : "",
    medic2 : "",
    medic3 : ""
  });

  const [a_afficher, seta_afficher] = useState({
    oxynorm : false,
    oxycontin : false,
    actiskenan : false,
    skenan : false,
    durogesic : false,
    laroxyl : false,
    zolpidem : false,
    methylphanidate : false
  });

  const [test, settest] = useState(true)


  const [renduOxynorm, setrenduOxynorm] = useState({
    mode_intervalle: true,
    nom : " ",
    nbs_cp1: "0",
    cp_gellule: true,
    poso1: 0,
    intervalle : 0,
    duree : 0,
  });

  const [renduOxycontinLp, setrenduOxycontinLp] = useState({
    mode_intervalle: false,
    nom : " ",
    diff_MS: false,
    nbs_cp1_M: "0",
    nbs_cp1_S: "0",
    nbs_cp2_M : "0",
    nbs_cp2_S : "0",
    cp_gellule: true,
    poso1M: 0,
    poso1S: 0,
    poso2M : 0,
    poso2S : 0,
    duree : 0,
  });

  const [renduActiskenan, setrenduActiskenan] = useState({
    mode_intervalle: true,
    nom : " ",
    nbs_cp1: "0",
    cp_gellule: false,
    poso1: 0,
    intervalle : 0,
    duree : 0,
  });

  const [renduSkenan, setrenduSkenan] = useState({
    mode_intervalle: false,
    nom : " ",
    diff_MS: false,
    nbs_cp1_M: "0",
    nbs_cp1_S: "0",
    nbs_cp2_M : "0",
    nbs_cp2_S : "0",
    cp_gellule: true,
    poso1M: 0,
    poso1S: 0,
    poso2M : 0,
    poso2S : 0,
    duree : 0,
  });

  const [renduDurogesic, setrenduDurogesic] = useState({
    mode_intervalle: true,
    nom : " ",
    nbs_cp1: "0",
    cp_gellule: false,
    poso1: 0,
    intervalle : 0,
    duree : 0,
  });


  const componentRef = useRef();


  const handleButtonClick = () => {
    setOptions({
      ...options,
      poso_oxycontin: "25"
    });
  };

  const handleMedic1Click = (medic) => {
    setOptions(prevOptions => ({
      ...prevOptions,
      medic1: medic
    }));
  }

  // ALD

    const [isAld, setIsAld] = useState(true);
  
    const handleToggle = () => {
      setIsAld(!isAld);
    };


      // Chevauchement

      const [isChevauchement, setisChevauchement] = useState(false);
  
      const handleChevauchement = () => {
        setisChevauchement(!isChevauchement);
      };

    // BIZONE
    const [isBizone, setisBizone] = useState(false);
  
    const handleToggle_bizone = () => {
      setisBizone(!isBizone);
    };

    // RAZ de la page
    const refreshPage = () => {
      setTimeout(() => {
        window.location.reload();
      }, 0); // 2000 millisecondes = 2 secondes
    };

    const [RAZ, setRAZ] = useState(false)

    useEffect(() => {
      if (a_afficher.oxynorm == true | a_afficher.oxycontin == true | a_afficher.actiskenan==true | a_afficher.skenan==true | a_afficher.durogesic==true | a_afficher.laroxyl==true){
        setRAZ(true);
      }
      
    }, [a_afficher]);

// Choix du type d'ordo

const [ordotype, setordotype] = useState(1);


  

  return (
    <div className='site_entier'>

    <div className="container">

        <div className="intro">
        <img src={Logo} width="250" height="40" />
        </div>


      <div className="medic1">

      <div className={RAZ ? 'visible' : 'caché'}>
          <button className="btn btn-warning" onClick={refreshPage} >REMISE A ZERO DE LA PAGE</button>
          {/* <button className="btn btn-warning" onClick={test_oxycodone} >TEST OXYCODONE</button> */}
      </div>
      <div className={!RAZ ? 'ChoixMedic' : 'caché'}>




      <div className={RAZ==false ? 'ChoixMedic_2' : 'caché'}>


        <div className="texte">Choisir un médicament :</div>
        <div className="bouton">
        <button className="btn btn-outline-primary btn-lg" onClick={() => {
  seta_afficher({
    oxynorm: true,
    oxycontin: true,
    actiskenan: false,
    skenan: false,
    durogesic: false,
    laroxyl: false,
    zolpidem: false,
    methylphanidate: false
  });

  // Appel de la fonction analy_oxycodone ici
  analy_oxycodone();
}}>Oxycontin LP et Oxynorm</button>

          <button className="btn btn-outline-success btn-lg" onClick={() => {seta_afficher({
    oxynorm : false,
    oxycontin : false,
    actiskenan : true,
    skenan : true,
    durogesic : false,
    laroxyl : false,
    zolpidem : false,
    methylphanidate : false
  });
  analy_morphine();
  }}>Skenan et Actiskenan</button>

          <button className="btn btn-outline-danger btn-lg" onClick={() => {seta_afficher({
    oxynorm : false,
    oxycontin : false,
    actiskenan : false,
    skenan : false,
    durogesic : true,
    laroxyl : false,
    zolpidem : false,
    methylphanidate : false
  });
  analy_duro();
  }}>Durogesic Patch +/- interdoses</button>

          {/* <button className="btn btn-outline-info btn-lg" onClick={() => {seta_afficher({
    oxynorm : false,
    oxycontin : false,
    actiskenan : false,
    skenan : false,
    durogesic : false,
    laroxyl : true,
    zolpidem : false,
    methylphanidate : false
  })}}>Laroxyl (seul)</button> */}

</div> 
{/* DIV HIDDEN */}

          {/* <button className="btn btn-outline-warning btn-lg" onClick={() => {seta_afficher({
    oxynorm : false,
    oxycontin : false,
    actiskenan : false,
    skenan : false,
    durogesic : false,
    laroxyl : false,
    zolpidem : true,
    methylphanidate : false
  })}}>Zolpidem</button> */}

          {/* <button className="btn btn-outline-primary btn-lg" onClick={() => {seta_afficher({
    oxynorm : false,
    oxycontin : false,
    actiskenan : false,
    skenan : false,
    durogesic : false,
    laroxyl : false,
    zolpidem : false,
    methylphanidate : true
  })}}>Methylphenidate</button> */}
        </div>
      </div>  


        <div className="ParamMedic">

        <div className={RAZ==false ? 'visible' : 'caché'}>
            <div className='zone_blanche'></div>
        </div>


            <div className={a_afficher.laroxyl == true ? 'visible' : 'caché'}>

                  <div className="medicactiv">
                  LAROXYL
                  </div>
                  <div className="medicselect">

                  {/* <Medic_interval
                      intervalle_mode={true}
                      cp_gel={true}  
                      choix_poso_init={[5, 10, 20]} // en mg
                      init_intervalle={4} // en heure
                      init_duree={28}
                      nom_medicament={"Oxynorm"}
                      setrendu={setrenduOxynorm}
                    /> */}
                  </div>
                  </div>

                  <div className={a_afficher.zolpidem == true ? 'visible' : 'caché'}>

                                      <div className="medicactiv">
                                      ZOLPIDEM
                                      </div>
                                      <div className="medicselect">

                                      {/* <Medic_interval
                                          intervalle_mode={true}
                                          cp_gel={true}  
                                          choix_poso_init={[5, 10, 20]} // en mg
                                          init_intervalle={4} // en heure
                                          init_duree={28}
                                          nom_medicament={"Oxynorm"}
                                          setrendu={setrenduOxynorm}
                                        /> */}
                                      </div>
                                      </div>                      

                                      <div className={a_afficher.methylphanidate == true ? 'visible' : 'caché'}>

                                  <div className="medicactiv">
                                  METHYLPHALIDATE
                                  </div>
                                  <div className="medicselect">

                                  {/* <Medic_interval
                                      intervalle_mode={true}
                                      cp_gel={true}  
                                      choix_poso_init={[5, 10, 20]} // en mg
                                      init_intervalle={4} // en heure
                                      init_duree={28}
                                      nom_medicament={"Oxynorm"}
                                      setrendu={setrenduOxynorm}
                                    /> */}
                                  </div>

                                  </div>

          {/* TEXTE ACCUEIL */}

          
          
          <div className="medigauche">
                  <div className={a_afficher.oxycontin == true ? 'visible' : 'caché'}>
                      <div className="medicactiv">OXYCONTIN LP </div>
                        <div className="medicselect">
                        <Medic_MS
                          intervalle_mode={false}
                          cp_gel={true}  
                          choix_poso_init={[5,10,15,20,30,40,60,80,120]} // idem cp si cp_gel=true sinon idem gel si false
                          init_intervalle={4} // en heure
                          init_duree={28}
                          nom_medicament={"Oxycontin LP"}
                          setrendu={setrenduOxycontinLp}
                        />
                        </div>
                    </div>

                    <div className={a_afficher.skenan == true ? 'visible' : 'caché'}>
                      <div className="medicactiv">SKENAN </div>
                        <div className="medicselect">
                        <Medic_MS
                          intervalle_mode={false}
                          cp_gel={false}  
                          choix_poso_init={[10, 30, 60, 100, 200]} // idem cp si cp_gel=true sinon idem gel si false
                          init_intervalle={4} // en heure
                          init_duree={28}
                          nom_medicament={"Skenan LP"}
                          setrendu={setrenduSkenan}
                        />
                        </div>
                    </div>

                    <div className={a_afficher.durogesic == true ? 'visible' : 'caché'}>

                              <div className="medicactiv">
                              DUROGESIC PATCH
                              </div>
                              <div className="medicselect">

                              <Medic_interval_patch
                                  intervalle_mode={true}
                                  cp_gel={true}  
                                  choix_poso_init={[12, 25, 50, 75, 100]} // en mg
                                  init_intervalle={4} // en heure
                                  init_duree={28}
                                  nom_medicament={"Durogesic patch"}
                                  setrendu={setrenduDurogesic}
                                />
                              </div>
                            </div>

                           

                        </div>


          <div className="medidroit">
                    <div className={a_afficher.oxynorm == true ? 'visible' : 'caché'}>

                              <div className="medicactiv">
                              OXYNORM
                              </div>
                              <div className="medicselect">

                              <Medic_interval
                                  intervalle_mode={true}
                                  cp_gel={true}  
                                  choix_poso_init_cp={[5,10,20]} // en mg
                                  choix_poso_init_gel={[5, 10, 20]} // en mg
                                  init_intervalle={4} // en heure
                                  init_duree={28}
                                  nom_medicament_cp={"Oxynormoro"}
                                  nom_medicament_gel={"Oxynorm"}
                                  setrendu={setrenduOxynorm}
                                />
                              </div>
                            </div>


                            <div className={a_afficher.actiskenan == true ? 'visible' : 'caché'}>

                                        <div className="medicactiv">
                                        ACTISKENAN
                                        </div>
                                        <div className="medicselect">

                                        <Medic_interval
                                            intervalle_mode={true}
                                            cp_gel={false}  
                                            choix_poso_init_cp={[1,5,10,20,30]} // en mg
                                            choix_poso_init_gel={[5,10,20,30]} // en mg
                                            init_intervalle={4} // en heure
                                            init_duree={28}
                                            nom_medicament_cp={"Actiskenan"}
                                            nom_medicament_gel={"Actiskenan"}
                                            setrendu={setrenduActiskenan}
                                />
                                        </div>
                                        </div>


                                      {/* SI DURO */}
                                    
                                        <div className={a_afficher.durogesic == true && a_afficher.oxynorm == false && a_afficher.actiskenan == false ? 'visible' : 'caché'}>


                                            <div className="ChoixInterdoseDuro">
                                              <div className="texte">Choisir une interdose (si besoin) :</div>
                                              <div className="bouton">
                                                <button className="btn btn-outline-primary" onClick={() => {seta_afficher({
                                          oxynorm : true,
                                          oxycontin : false,
                                          actiskenan : false,
                                          skenan : false,
                                          durogesic : true,
                                          laroxyl : false,
                                          zolpidem : false,
                                          methylphanidate : false
                                        })}}>Oxynorm</button>

                                                <button className="btn btn-outline-success" onClick={() => {seta_afficher({
                                          oxynorm : false,
                                          oxycontin : false,
                                          actiskenan : true,
                                          skenan : false,
                                          durogesic : true,
                                          laroxyl : false,
                                          zolpidem : false,
                                          methylphanidate : false
                                        })}}>Actiskenan</button>
                                        </div>
                                        </div>
                                        

                                        </div>




            </div>
        </div>


      </div>
      
      {/* <div className="medic2">Medic2</div>
      <div className="medic3">Medic3</div> */}
      <div className={RAZ ? 'pied' : 'caché'}>

    <div className='boggle'>


      <div className='btn-ALD'>
        <div className={`ToggleSwitch ${isAld ? 'ToggleSwitch-on' : 'ToggleSwitch-off'}`} onClick={handleToggle}>
          <div className="ToggleSwitch-handle"></div>
          <span className="ToggleSwitch-label">{isAld ? 'En ALD' : 'Hors ALD'}</span>
        </div>
      </div>

      <div className='btn-ALD'>
        <div className={`ToggleSwitch ${isChevauchement ? 'ToggleSwitch-on' : 'ToggleSwitch-off'}`} onClick={handleChevauchement}>
          <div className="ToggleSwitch-handle"></div>
          <span className="ToggleSwitch-label">{isChevauchement ? 'Chevauchement' : 'Pas de chevauchement'}</span>
        </div>
      </div>

      {/* <div className='btn-ALD'>
        <div className={`ToggleSwitch ${isAld ? 'ToggleSwitch-on' : 'ToggleSwitch-off'}`} onClick={handleToggle}>
          <div className="ToggleSwitch-handle"></div>
          <span className="ToggleSwitch-label">{!isAld ? 'Pas de PARECATAMOL ajouté' : 'PARACETAMOL 4G/j ajouté'}</span>
        </div>
      </div> */}

      

    </div>

    <div className='choix_type_ordo'>

        <div className='choix_type_ordo_inf'>
          <p>Changer le type d'ordonnance (si besoin) : </p>
        </div>

        <div className={ordotype==1 ? "choix_type_ordo_inf_encardé" : "choix_type_ordo_inf"}>
            <button
            style={{
              border: 'none',
              background: 'none',
              padding: 0,
              cursor: 'pointer',
            }}
            onClick={() => {setordotype(1)}}
          >
            <img src={Ordo1} width="100" height="120" alt="Mon bouton" />
          </button>
        </div>

        <div className={ordotype==2 ? "choix_type_ordo_inf_encardé" : "choix_type_ordo_inf"}>
          <button
              style={{
                border: 'none',
                background: 'none',
                padding: 0,
                cursor: 'pointer',
              }}
              onClick={() => {setordotype(2)}}
            >
              <img src={Ordo2} width="100" height="120" alt="Mon bouton" />
            </button>
        </div>

        <div className={ordotype==3 ? "choix_type_ordo_inf_encardé" : "choix_type_ordo_inf"}>
          <button
              style={{
                border: 'none',
                background: 'none',
                padding: 0,
                cursor: 'pointer',
              }}
              onClick={() => {setordotype(3)}}
            >
              <img src={Ordo3} width="100" height="120" alt="Mon bouton" />
            </button>
        </div>
      

    </div>

    

        <div className='btn-imprimer'>
          <ReactToPrint
            trigger={() => <button className="btn btn-danger">IMPRIMER L'ORDONNANCE</button>}
            content={() => componentRef.current}
          />
        </div>

      </div>
        

      <div className={!RAZ ? "explication" : "caché"}>
     
        <div className='textaccueil'>
          <div className='text_gauche'>
            <p className="custom-paragraph">Rédigez en quelques clic le contenu de vos ordonnances sécurisées d'antalgique de pallier III </p>
            <p className="custom-paragraph3">C'est gratuit, anonyme et sans inscription</p>
            <p className="custom-paragraph2">Cet outil à destination des médecins permet de gagner du temps lors de la rédaction d'ordonnance d'antalgique de pallier III. Ce n'est pas un outil d'aide à la prescription, ni un dispositif médical. L'impression doit se faire sur une ordonnance sécurisée. Vous restez responsable de votre prescription. Veuillez vérifier la posologie avant signature de l'ordonnance.</p>

          </div>
                        <img
                      src={i1}
                      width="505"
                      height="325"
                      style={{
                        opacity: 0.7,
                        marginLeft: '70px',
                        border: '2px solid transparent', // Ajoute une bordure transparente pour pouvoir ajouter un flou
                        borderRadius: '20px', // Définit le rayon de courbure des coins
                        filter: 'blur(0px)', // Ajoute un flou de 5 pixels à l'image
                      }}
                      alt="Description de l'image"
                    />
              </div>

              {/* <div className='partenaire'>
              </div> */}
              
                      <div className='bnt_contact'>
                          <Popup trigger={<button className="btn btn-outline-dark"> Contact - mentions légales </button>} modal>
                              <div>

<p>MENTIONS LÉGALES</p>


<p>Le présent site, accessible à l’URL www.ordodouleur.fr (le « Site »).
Site créé sur l’initiative personnelle d’un interne en médecine.</p>
<p>Ce n'est pas un outil d'aide à la prescription, ni un dispositif médical. L'impression doit se faire sur une ordonnance sécurisée. Vous restez responsable de votre prescription. Veuillez vérifier la posologie avant signature de l'ordonnance.</p>

<p>Hébergement</p>

<p>Le Site est hébergé par la société Hostinger International LTD, situé , (contact téléphonique ou email : https://www.hostinger.fr/contact).</p>

<p>Politique de la vie privée</p>
<p>Aucunes données ne sont stockées. La création de l’ordonnance se fait directement sur l’ordinateur de l’utilisateur et non pas sur un serveur (API). Les données ayant permis de générer l’ordonnance ne sont donc pas enregistrées.</p>

<p>Est mis en place uniquement un monitorage du nombre d’ordonnances générées par jour par le site (firebase). Le contenu des ordonnances n'est pas monitoré</p>

<p>Nous contacter </p>
<p>Par email : ordodouleur@gmail.com</p>
<p>Copyright : tous droits réservés </p>

                              </div>
                          </Popup>
                        </div>

      </div>

      </div>



      <div>

    </div>


    {/* A IMPRIMER */}
      <div className="row" style={{ display: "none" }}>
        <div className="col-12" ref={componentRef} >

{/* ordo type 1 */}
          <div className={ordotype==1 ? 'imp_ordo1_a' : "caché"}>
              <div className='imp_ordo1_date'>
                <strong>Date : </strong> <DateInLetters/>
              </div>

              <div className='imp_ordo1_ind'>
                  <strong>Identité du patient : </strong>
              </div>

              <div className={isAld?'message_ald' : 'caché'}>
              <strong>Prescription en ALD</strong>
              </div>

              <div className={isChevauchement?'message_chevau' : 'caché'}>
              <strong>Chevauchement autorisé</strong>
              </div>


              <div className='imp_ordo1_lp'>
                {a_afficher.oxycontin? <AfficheurMS donnee = {renduOxycontinLp} /> : null}
                {a_afficher.skenan? <AfficheurMS donnee = {renduSkenan} /> : null}
                {a_afficher.durogesic? <Afficheur_patch donnee = {renduDurogesic} /> : null}
              </div>

              <div className='imp_ordo1_inter'>
                {a_afficher.oxynorm? <Afficheur donnee = {renduOxynorm} /> : null}
                {a_afficher.actiskenan? <Afficheur donnee = {renduActiskenan} /> : null}
              </div>

          </div>

          {/* ordo type 2 */}
          <div className={ordotype==2 ? 'imp_ordo2_a' : "caché"}>
            <div className={isAld? "imp_ordo2_a_ald" : "imp_ordo2_a_hordald"}>
                <div className='imp_ordo2_date'>
                  <strong>Date : </strong> <DateInLetters/>
                </div>

                <div className={isChevauchement?'message_chevau' : 'caché'}>
                <strong>Chevauchement autorisé</strong>
                </div>

                {/* <div className='imp_ordo2_ind'>
                    <strong>Identité du patient : </strong>
                </div> */}

            
                <div className='imp_ordo2_lp'>
                  {a_afficher.oxycontin? <AfficheurMS donnee = {renduOxycontinLp} /> : null}
                  {a_afficher.skenan? <AfficheurMS donnee = {renduSkenan} /> : null}
                  {a_afficher.durogesic? <Afficheur_patch donnee = {renduDurogesic} /> : null}
                </div>

                <div className='imp_ordo2_inter'>
                  {a_afficher.oxynorm? <Afficheur donnee = {renduOxynorm} /> : null}
                  {a_afficher.actiskenan? <Afficheur donnee = {renduActiskenan} /> : null}
                </div>
              </div>
            </div>

          {/* ordo type 3 */}

          <div className={ordotype==3 ? 'imp_ordo3_a' : "caché"}>
              <div className='imp_ordo3_date'>
                <strong>Date : </strong> <DateInLetters/>
              </div>

              <div className='imp_ordo3_ind'>
                  <strong>Identité du patient : </strong>
              </div>


              <div className={isAld?'message_ald' : 'caché'}>
              <strong>Prescription en ALD</strong>
              </div>

              <div className={isChevauchement?'message_chevau' : 'caché'}>
              <strong>Chevauchement autorisé</strong>
              </div>
          
              <div className='imp_ordo3_lp'>
                {a_afficher.oxycontin? <AfficheurMS donnee = {renduOxycontinLp} /> : null}
                {a_afficher.skenan? <AfficheurMS donnee = {renduSkenan} /> : null}
                {a_afficher.durogesic? <Afficheur_patch donnee = {renduDurogesic} /> : null}
              </div>

              <div className='imp_ordo3_inter'>
                {a_afficher.oxynorm? <Afficheur donnee = {renduOxynorm} /> : null}
                {a_afficher.actiskenan? <Afficheur donnee = {renduActiskenan} /> : null}
              </div>

          </div>

        </div>

      </div>

      

    </div>
  );
}

export default App;
