import React from 'react'
import react, { useState, useRef, useEffect} from 'react';
import './App.css';


const Ligne_patch = ({
    choix_poso_init, nbs_cp, poso_cp, setqqte_Ligne, setchoix_poso_loc_ligne
}) => {

    const [qqtParPrise, setQqtParPrise] = useState(1);
    const [choix_poso_init_loc, setchoix_poso_init_loc] = useState(choix_poso_init);
    const [choix_poso_loc, setchoix_poso_loc] = useState(choix_poso_init[0]);



    const handleDropdownChange = (event) => {
      setQqtParPrise(parseInt(event.target.value));
      setqqte_Ligne(parseInt(event.target.value));
    };

    const handleDropdownChange_poso = (event) => {
        setchoix_poso_loc(parseInt(event.target.value));
        setchoix_poso_loc_ligne(parseInt(event.target.value));
      };


        return (
            <div className="ligne_cp">
                <select id="dropdown" value={qqtParPrise} onChange={handleDropdownChange} style={{ width: '40px'}}>
                    <option value={0}>0</option>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                    <option value={6}>6</option>
                    <option value={7}>7</option>
                    <option value={8}>8</option>
                    <option value={9}>9</option>
                </select> {(qqtParPrise==1)|(qqtParPrise==0) ? "patch" : "patchs"} de {" "}


            <select id="dropdown" value={choix_poso_loc} onChange={handleDropdownChange_poso}>                
            {choix_poso_init_loc.map((choix_poso_init_loc) => (
                <option key={choix_poso_init_loc} value={choix_poso_init_loc}>{choix_poso_init_loc}</option>
            ))}
            </select>
            {" "} µg/h
            </div>
          )

    }


export default Ligne_patch
