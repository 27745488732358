import React from 'react'
import react, { useState, useRef, useEffect} from 'react';
import './App.css';
import NombreEnLettres from './conver.js';
import Ligne from './Ligne';



const AfficheurMS = ({
    donnee
}) => {

if (donnee.cp_gellule==true & donnee.diff_MS==false){
  if (donnee.poso2M == null){
    return (
      <div style={{fontSize: '100%'}}>
        <strong>{donnee.nom}</strong> : <NombreEnLettres nombre={donnee.nbs_cp1_M} />  {donnee.nbs_cp1_M ==1 ? "comprimé" : "comprimés"} de <NombreEnLettres nombre={donnee.poso1M} /> milligrammes ({donnee.poso1M} mg), à prendre toute les douze heures (matin et soir), pendant <NombreEnLettres nombre={donnee.duree} /> jours
      <br/>
      </div>
      );
  }

  if (donnee.poso2M != null){
    return (
      <div style={{fontSize: '100%'}}>
        <strong>{donnee.nom}</strong> : <NombreEnLettres nombre={donnee.nbs_cp1_M} />  {donnee.nbs_cp1_M ==1 ? "comprimé" : "comprimés"} de <NombreEnLettres nombre={donnee.poso1M} /> milligrammes ({donnee.poso1M} mg) et <NombreEnLettres nombre={donnee.nbs_cp2_M} />  {donnee.nbs_cp2_M ==1 ? "comprimé" : "comprimés"} de <NombreEnLettres nombre={donnee.poso2M} /> milligrammes ({donnee.poso2M} mg) par prise, à prendre toute les douze heures (matin et soir), pendant <NombreEnLettres nombre={donnee.duree} /> jours
      <br/>
      </div>
      );
  }
}

if (donnee.cp_gellule==true & donnee.diff_MS== true){
  

  if (donnee.poso2M == null & donnee.poso2S == null){
    return (
      <div style={{fontSize: '100%'}}>
        <strong>{donnee.nom}</strong> : <NombreEnLettres nombre={donnee.nbs_cp1_M} />  {donnee.nbs_cp1_M ==1 ? "comprimé" : "comprimés"} de <NombreEnLettres nombre={donnee.poso1M} /> milligrammes ({donnee.poso1M} mg) à prendre le matin et <NombreEnLettres nombre={donnee.nbs_cp1_S} />  {donnee.nbs_cp1_S ==1 ? "comprimé" : "comprimés"} de <NombreEnLettres nombre={donnee.poso1S} /> milligrammes ({donnee.poso1S} mg) à prendre le soir (à douze heures d'intervalle), pendant <NombreEnLettres nombre={donnee.duree} /> jours
      <br/>
      </div>
      );
  }

  if (donnee.poso2M != null & donnee.poso2S == null){
    return (
      <div style={{fontSize: '100%'}}>
        <strong>{donnee.nom}</strong> : 
        <br/>
        <span >
        <div style={{marginLeft:'0px'}}>
        une prise toute les douze heures : <br/>
        - <span style={{textDecoration:'underline'}}>le matin</span> : <NombreEnLettres nombre={donnee.nbs_cp1_M} />  {donnee.nbs_cp1_M ==1 ? "comprimé" : "comprimés"} de <NombreEnLettres nombre={donnee.poso1M} /> milligrammes ({donnee.poso1M} mg) et <NombreEnLettres nombre={donnee.nbs_cp2_M} />  {donnee.nbs_cp2_M ==1 ? "comprimé" : "comprimés"} de <NombreEnLettres nombre={donnee.poso2M} /> milligrammes ({donnee.poso2M} mg)

        <br/>
        - <span style={{textDecoration:'underline'}}>le soir</span> : <NombreEnLettres nombre={donnee.nbs_cp1_S} />  {donnee.nbs_cp1_S ==1 ? "comprimé" : "comprimés"} de <NombreEnLettres nombre={donnee.poso1S} /> milligrammes ({donnee.poso1S} mg)
        <br/>
        <div style={{marginTop:'7px'}}>pendant <NombreEnLettres nombre={donnee.duree} /> jours</div>
      <br/>
      </div>
      </span>
      </div>
      );
  }

  if (donnee.poso2M == null & donnee.poso2S != null){
    return (
      <div style={{fontSize: '100%'}}>
        <strong>{donnee.nom}</strong> : 
        <br/>
        <span >
        <div style={{marginLeft:'0px'}}>
        une prise toute les douze heures : <br/>
        - <span style={{textDecoration:'underline'}}>le matin</span> : <NombreEnLettres nombre={donnee.nbs_cp1_M} />  {donnee.nbs_cp1_M ==1 ? "comprimé" : "comprimés"} de <NombreEnLettres nombre={donnee.poso1M} /> milligrammes ({donnee.poso1M} mg)
        <br/>
        - <span style={{textDecoration:'underline'}}>le soir</span> : <NombreEnLettres nombre={donnee.nbs_cp1_S} />  {donnee.nbs_cp1_S ==1 ? "comprimé" : "comprimés"} de <NombreEnLettres nombre={donnee.poso1S} /> milligrammes ({donnee.poso1S} mg) et <NombreEnLettres nombre={donnee.nbs_cp2_S} />  {donnee.nbs_cp2_S ==1 ? "comprimé" : "comprimés"} de <NombreEnLettres nombre={donnee.poso2S} /> milligrammes ({donnee.poso2S} mg)
        <br/>
        <div style={{marginTop:'7px'}}>pendant <NombreEnLettres nombre={donnee.duree} /> jours</div>
      <br/>
      </div>
      </span>
      </div>
      );
  }

  if (donnee.poso2M != null & donnee.poso2S != null){
    return (
      <div style={{fontSize: '100%'}}>
        <strong>{donnee.nom}</strong> : 
        <br/>
        <span >
        <div style={{marginLeft:'0px'}}>
        une prise toute les douze heures : <br/>
        - <span style={{textDecoration:'underline'}}>le matin</span> : <NombreEnLettres nombre={donnee.nbs_cp1_M} />  {donnee.nbs_cp1_M ==1 ? "comprimé" : "comprimés"} de <NombreEnLettres nombre={donnee.poso1M} /> milligrammes ({donnee.poso1M} mg) et <NombreEnLettres nombre={donnee.nbs_cp2_M} />  {donnee.nbs_cp2_M ==1 ? "comprimé" : "comprimés"} de <NombreEnLettres nombre={donnee.poso2M} /> milligrammes ({donnee.poso2M} mg)
        <br/>
        - <span style={{textDecoration:'underline'}}>le soir</span> : <NombreEnLettres nombre={donnee.nbs_cp1_S} />  {donnee.nbs_cp1_S ==1 ? "comprimé" : "comprimés"} de <NombreEnLettres nombre={donnee.poso1S} /> milligrammes ({donnee.poso1S} mg) et <NombreEnLettres nombre={donnee.nbs_cp2_S} />  {donnee.nbs_cp2_S ==1 ? "comprimé" : "comprimés"} de <NombreEnLettres nombre={donnee.poso2S} /> milligrammes ({donnee.poso2S} mg)
        <br/>
        <div style={{marginTop:'7px'}}>pendant <NombreEnLettres nombre={donnee.duree} /> jours</div>
      <br/>
      </div>
      </span>
      </div>
      );
  }
}

//GELLULE

if (donnee.cp_gellule==false & donnee.diff_MS==false){
  if (donnee.poso2M == null){
    return (
      <div style={{fontSize: '100%'}}>
        <strong>{donnee.nom}</strong> : {(donnee.nbs_cp1_M==1) ? "une" : <NombreEnLettres nombre={donnee.nbs_cp1_M} />}   {donnee.nbs_cp1_M ==1 ? "gélule" : "gélules"} de <NombreEnLettres nombre={donnee.poso1M} /> milligrammes ({donnee.poso1M} mg), à prendre toute les douze heures (matin et soir), pendant <NombreEnLettres nombre={donnee.duree} /> jours
      <br/>
      </div>
      );
  }

  if (donnee.poso2M != null){
    return (
      <div style={{fontSize: '100%'}}>
        <strong>{donnee.nom}</strong> : {(donnee.nbs_cp1_M==1) ? "une" : <NombreEnLettres nombre={donnee.nbs_cp1_M} />}   {donnee.nbs_cp1_M ==1 ? "gélule" : "gélules"} de <NombreEnLettres nombre={donnee.poso1M} /> milligrammes ({donnee.poso1M} mg) et {(donnee.nbs_cp2_M==1) ? "une" : <NombreEnLettres nombre={donnee.nbs_cp2_M} />} {donnee.nbs_cp2_M ==1 ? "gélule" : "gélules"} de <NombreEnLettres nombre={donnee.poso2M} /> milligrammes ({donnee.poso2M} mg) par prise, à prendre toute les douze heures (matin et soir), pendant <NombreEnLettres nombre={donnee.duree} /> jours
      <br/>
      </div>
      );
  }
}

// GELLUEL DIFF

if (donnee.cp_gellule==false & donnee.diff_MS== true){
  

  if (donnee.poso2M == null & donnee.poso2S == null){
    return (
      <div style={{fontSize: '100%'}}>
        <strong>{donnee.nom}</strong> : {(donnee.nbs_cp1_M==1) ? "une" : <NombreEnLettres nombre={donnee.nbs_cp1_M} />}  {donnee.nbs_cp1_M ==1 ? "gélule" : "gélules"} de <NombreEnLettres nombre={donnee.poso1M} /> milligrammes ({donnee.poso1M} mg) à prendre le matin et {(donnee.nbs_cp1_S==1) ? "une" : <NombreEnLettres nombre={donnee.nbs_cp1_S} />} {donnee.nbs_cp1_S ==1 ? "gélule" : "gélules"} de <NombreEnLettres nombre={donnee.poso1S} /> milligrammes ({donnee.poso1S} mg) à prendre le soir (à douze heures d'intervalle), pendant <NombreEnLettres nombre={donnee.duree} /> jours
      <br/>
      </div>
      );
  }

  if (donnee.poso2M != null & donnee.poso2S == null){
    return (
      <div style={{fontSize: '100%'}}>
        <strong>{donnee.nom}</strong> : 
        <br/>
        <span >
        <div style={{marginLeft:'0px'}}>
        une prise toute les douze heures : <br/>
        - <span style={{textDecoration:'underline'}}>le matin</span> : {(donnee.nbs_cp1_M==1) ? "une" : <NombreEnLettres nombre={donnee.nbs_cp1_M} />}  {donnee.nbs_cp1_M ==1 ? "gélule" : "gélules"} de <NombreEnLettres nombre={donnee.poso1M} /> milligrammes ({donnee.poso1M} mg) et {(donnee.nbs_cp2_M==1) ? "une" : <NombreEnLettres nombre={donnee.nbs_cp2_M} />}  {donnee.nbs_cp2_M ==1 ? "gélule" : "gélules"} de <NombreEnLettres nombre={donnee.poso2M} /> milligrammes ({donnee.poso2M} mg)

        <br/>
        - <span style={{textDecoration:'underline'}}>le soir</span> : {(donnee.nbs_cp1_M==1) ? "une" : <NombreEnLettres nombre={donnee.nbs_cp1_S} />}  {donnee.nbs_cp1_S ==1 ? "gélule" : "gélules"} de <NombreEnLettres nombre={donnee.poso1S} /> milligrammes ({donnee.poso1S} mg)
        <br/>
        <div style={{marginTop:'7px'}}>pendant <NombreEnLettres nombre={donnee.duree} /> jours</div>
      <br/>
      </div>
      </span>
      </div>
      );
  }

  if (donnee.poso2M == null & donnee.poso2S != null){
    return (
      <div style={{fontSize: '100%'}}>
        <strong>{donnee.nom}</strong> : 
        <br/>
        <span >
        <div style={{marginLeft:'0px'}}>
        une prise toute les douze heures : <br/>
        - <span style={{textDecoration:'underline'}}>le matin</span> : {(donnee.nbs_cp1_M==1) ? "une" : <NombreEnLettres nombre={donnee.nbs_cp1_M} />}  {donnee.nbs_cp1_M ==1 ? "gélule" : "gélules"} de <NombreEnLettres nombre={donnee.poso1M} /> milligrammes ({donnee.poso1M} mg)
        <br/>
        - <span style={{textDecoration:'underline'}}>le soir</span> : {(donnee.nbs_cp1_S==1) ? "une" : <NombreEnLettres nombre={donnee.nbs_cp1_S} />}  {donnee.nbs_cp1_S ==1 ? "gélule" : "gélules"} de <NombreEnLettres nombre={donnee.poso1S} /> milligrammes ({donnee.poso1S} mg) et {(donnee.nbs_cp2_S==1) ? "une" : <NombreEnLettres nombre={donnee.nbs_cp2_S} />}  {donnee.nbs_cp2_S ==1 ? "gélule" : "gélules"} de <NombreEnLettres nombre={donnee.poso2S} /> milligrammes ({donnee.poso2S} mg)
        <br/>
        <div style={{marginTop:'7px'}}>pendant <NombreEnLettres nombre={donnee.duree} /> jours</div>
      <br/>
      </div>
      </span>
      </div>
      );
  }

  if (donnee.poso2M != null & donnee.poso2S != null){
    return (
      <div style={{fontSize: '100%'}}>
        <strong>{donnee.nom}</strong> : 
        <br/>
        <span >
        <div style={{marginLeft:'0px'}}>
        une prise toute les douze heures : <br/>
        - <span style={{textDecoration:'underline'}}>le matin</span> : {(donnee.nbs_cp1_M==1) ? "une" : <NombreEnLettres nombre={donnee.nbs_cp1_M} />}  {donnee.nbs_cp1_M ==1 ? "gélule" : "gélules"} de <NombreEnLettres nombre={donnee.poso1M} /> milligrammes ({donnee.poso1M} mg) et {(donnee.nbs_cp2_M==1) ? "une" : <NombreEnLettres nombre={donnee.nbs_cp2_M} />}  {donnee.nbs_cp2_M ==1 ? "gélule" : "gélules"} de <NombreEnLettres nombre={donnee.poso2M} /> milligrammes ({donnee.poso2M} mg)
        <br/>
        - <span style={{textDecoration:'underline'}}>le soir</span> : {(donnee.nbs_cp1_S==1) ? "une" : <NombreEnLettres nombre={donnee.nbs_cp1_S} />}  {donnee.nbs_cp1_S ==1 ? "gélule" : "gélules"} de <NombreEnLettres nombre={donnee.poso1S} /> milligrammes ({donnee.poso1S} mg) et {(donnee.nbs_cp2_S==1) ? "une" : <NombreEnLettres nombre={donnee.nbs_cp2_S} />}  {donnee.nbs_cp2_S ==1 ? "gélule" : "gélules"} de <NombreEnLettres nombre={donnee.poso2S} /> milligrammes ({donnee.poso2S} mg)
        <br/>
        <div style={{marginTop:'7px'}}>pendant <NombreEnLettres nombre={donnee.duree} /> jours</div>
      <br/>
      </div>
      </span>
      </div>
      );
  }
}


}

export default AfficheurMS
