import React from 'react'
import react, { useState, useRef, useEffect} from 'react';
import './App.css';
import NombreEnLettres from './conver.js';
import Ligne from './Ligne';



const Medic_MS = ({
    posocp1, posocp2,
    cp_gel, choix_poso_init,
    init_intervalle,
    nom_medicament, 
    setrendu,
    intervalle_mode,
    init_duree
}) => {

  const [qqte_Ligne1_M, setqqte_Ligne1_M] = useState(1);
  const [qqte_Ligne1_S, setqqte_Ligne1_S] = useState(1);

  const [qqte_Ligne2_M, setqqte_Ligne2_M] = useState(1);
  const [qqte_Ligne2_S, setqqte_Ligne2_S] = useState(1);

  const [diff_MS, setdiff_MS] = useState(false); // Différenciation poso matin et soir ??


  const [ajout_cpM, setajout_cpM] = useState(false);
  const [ajout_cpS, setajout_cpS] = useState(false);

  const [poso_cp1M, setposo_cp1M] = useState(choix_poso_init[0]);
  const [poso_cp2M, setposo_cp2M] = useState(null);

  const [poso_cp1S, setposo_cp1S] = useState(choix_poso_init[0]);
  const [poso_cp2S, setposo_cp2S] = useState(null);

  const [duree, setduree] = useState(init_duree);
  const [duree_2, setduree_2] = useState(null);


  useEffect(() => {
    
    setrendu(
      {
    mode_intervalle: false,
    nom : nom_medicament,

    diff_MS: diff_MS,

    nbs_cp1_M: qqte_Ligne1_M,
    nbs_cp1_S: qqte_Ligne1_S,

    nbs_cp2_M : qqte_Ligne2_M,
    nbs_cp2_S : qqte_Ligne2_S,

    cp_gellule: cp_gel,

    poso1M: poso_cp1M,
    poso1S: poso_cp1S,

    poso2M : poso_cp2M,
    poso2S : poso_cp2S,

    duree : duree+duree_2,
  }
    )

  });



  const handleDropdownChangeduree = (event) => {
    setduree(parseInt(event.target.value));
  };

  if (diff_MS == false){ 
    return (
        <div className="med_a">
          <div className="med_a_2">
            <div className='text_poso'><span>Posologie (par prise) : {" "} <br/> </span> <span style={{ fontWeight:'bold'}}>matin et soir</span></div>
                <div className='ligne'>
                    <span className='ligne_2'><Ligne
                            cp_gel={cp_gel}
                            setqqte_Ligne={setqqte_Ligne1_M}
                            choix_poso_init={choix_poso_init}
                            setchoix_poso_loc_ligne={setposo_cp1M}
                            // récupere la poso
                            /> 
                            </span>
                   
                    {ajout_cpM==false?<span>
                      <button className="btn btn-outline-dark btn-sm" onClick={() => {setajout_cpM(true); ; setposo_cp2M(choix_poso_init[0]); }}style={{fontSize: '80%'}}><span className='texte_bouton_ajout'>Ajout d'{cp_gel==true ? "un cp" : "une gellule"} de posologie différente</span></button>
                            </span>:null}

                    {ajout_cpM==true?<span><Ligne
                            cp_gel={cp_gel}
                            setqqte_Ligne={setqqte_Ligne2_M}
                            choix_poso_init={choix_poso_init}
                            setchoix_poso_loc_ligne={setposo_cp2M}
                            // récupere la poso
                            /> </span>:null}
                </div>
          </div>

          <div className='text_btn_soir'>
            <button className="btn btn-outline-dark" onClick={() => {setdiff_MS(true)}}style={{fontSize: '90%'}}><span className='texte_bouton_ajout'>Cliquez ici pour différencier la posologie du matin et du soir</span></button>
          </div>

        

          <div className="duree">Durée de prescription : {" "}
          {(duree == 7 | duree==14 | duree==21 | duree==28 ) ?  <select id="dropdown" value={duree} onChange={handleDropdownChangeduree} style={{ width: '60px'}}>
                    <option value={7}>7</option>
                    <option value={14}>14</option>
                    <option value={21}>21</option>
                    <option value={28}>28</option>
                    <option value={0}>autre</option>
                </select> : 
                          <input type="text" value={duree_2} onChange={(e) => setduree_2(e.target.value)}  style={{ width: '50px'}} />
                        }
                
               {" "} jours

                </div>
        </div>
      );}

      if (diff_MS == true){ 
        return (
            <div className="med_a">
              <div className="med_a_2">
                <div className='text_poso'><span>Posologie (par prise) : {" "} <br/> </span> <span style={{ fontWeight:'bold'}}>matin</span></div>
                    <div className='ligne'>
                        <span className='ligne_2'><Ligne
                                cp_gel={cp_gel}
                                setqqte_Ligne={setqqte_Ligne1_M}
                                choix_poso_init={choix_poso_init}
                                setchoix_poso_loc_ligne={setposo_cp1M}
                                // récupere la poso
                                /> 
                                </span>
                       
                        {ajout_cpM==false?<span>
                          <button className="btn btn-outline-dark btn-sm" onClick={() => {setajout_cpM(true); ; setposo_cp2M(choix_poso_init[0]); }}style={{fontSize: '80%'}}><span className='texte_bouton_ajout'>Ajout d'{cp_gel==true ? "un cp" : "une gellule"} de posologie différente</span></button>
                                </span>:null}
    
                        {ajout_cpM==true?<span><Ligne
                                cp_gel={cp_gel}
                                setqqte_Ligne={setqqte_Ligne2_M}
                                choix_poso_init={choix_poso_init}
                                setchoix_poso_loc_ligne={setposo_cp2M}
                                // récupere la poso
                                /> </span>:null}
                    </div>
              </div>
    
    {/* SOIR */}
              <div className="med_a_2">
                <div className='text_poso'><span>Posologie (par prise) : {" "} <br/> </span> <span style={{ fontWeight:'bold'}}>soir</span></div>
                    <div className='ligne'>
                        <span className='ligne_2'><Ligne
                                cp_gel={cp_gel}
                                setqqte_Ligne={setqqte_Ligne1_S}
                                choix_poso_init={choix_poso_init}
                                setchoix_poso_loc_ligne={setposo_cp1S}
                                // récupere la poso
                                /> 
                                </span>
                       
                        {ajout_cpS==false?<span>
                          <button className="btn btn-outline-dark btn-sm" onClick={() => {setajout_cpS(true); ; setposo_cp2S(choix_poso_init[0]); }}style={{fontSize: '80%'}}><span className='texte_bouton_ajout'>Ajout d'{cp_gel==true ? "un cp" : "une gellule"} de posologie différente</span></button>
                                </span>:null}
    
                        {ajout_cpS==true?<span><Ligne
                                cp_gel={cp_gel}
                                setqqte_Ligne={setqqte_Ligne2_S}
                                choix_poso_init={choix_poso_init}
                                setchoix_poso_loc_ligne={setposo_cp2S}
                                // récupere la poso
                                /> </span>:null}
                    </div>
              </div>
    
              <div className="duree">Durée de prescription : {" "}
              {(duree == 7 | duree==14 | duree==21 | duree==28 ) ?  <select id="dropdown" value={duree} onChange={handleDropdownChangeduree} style={{ width: '60px'}}>
                        <option value={7}>7</option>
                        <option value={14}>14</option>
                        <option value={21}>21</option>
                        <option value={28}>28</option>
                        <option value={0}>autre</option>
                    </select> : 
                              <input type="text" value={duree_2} onChange={(e) => setduree_2(e.target.value)}  style={{ width: '50px'}} />
                            }
                    
                   {" "} jours
    
                    </div>
            </div>
          );}      

}

export default Medic_MS
