import React from 'react';
import NombreEnLettres from './conver.js';


const daysOfWeek = [
  'dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'
];

const monthsOfYear = [
  'janvier', 'février', 'mars', 'avril', 'mai', 'juin',
  'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'
];

function DateInLetters() {
  const today = new Date();
  const dayOfWeek = daysOfWeek[today.getDay()];
  const dayOfMonth = today.getDate();
  const month = monthsOfYear[today.getMonth()];
  const year = today.getFullYear();

  const formattedDate = `${dayOfWeek} ${dayOfMonth} ${month} ${year}`;

  return (
    <div style={{marginLeft: '10px'}}>
      <p>{formattedDate}</p>
    </div>
  );
}

export default DateInLetters;
