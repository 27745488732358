import React from 'react'
import react, { useState, useRef, useEffect} from 'react';
import './App.css';
import NombreEnLettres from './conver.js';
import Ligne from './Ligne';



const Afficheur_patch = ({
    donnee
}) => {

  if (donnee.poso2 == null){
    return (

      <div style={{fontSize: '100%'}}>
        <strong>{donnee.nom}</strong> : <NombreEnLettres nombre={donnee.nbs_cp1} />  {donnee.nbs_cp1 ==1 ? "patch" : "patchs"} de <NombreEnLettres nombre={donnee.poso1} /> microgramme par heure ({donnee.poso1} µg/h), à changer toutes les soixante-douze heures (tous les 3 jours), pendant <NombreEnLettres nombre={donnee.duree} /> jours
      <br/>
      </div>
        // <div>
        //      {donnee[0]} : {donnee[1]} comprimé de {donnee[2]} mg
        // </div>
      );
  }

  if (donnee.poso2 != null){
    return (

      <div style={{fontSize: '100%'}}>

        <strong>{donnee.nom}</strong> : <NombreEnLettres nombre={donnee.nbs_cp1} />  {donnee.nbs_cp1 ==1 ? "patch" : "patchs"} de <NombreEnLettres nombre={donnee.poso1} /> microgramme par heure ({donnee.poso1} µg/h) et <NombreEnLettres nombre={donnee.nbs_cp2} /> {donnee.nbs_cp2 ==1 ? "patch" : "patchs"} de <NombreEnLettres nombre={donnee.poso2} /> microgramme par heure ({donnee.poso2} µg/h), à changer toutes les soixante-douze heures (tous les 3 jours), pendant <NombreEnLettres nombre={donnee.duree} /> jours

      </div>
        // <div>
        //      {donnee[0]} : {donnee[1]} comprimé de {donnee[2]} mg
        // </div>
      );
  
}



}

export default Afficheur_patch
